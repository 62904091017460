import {createColumnHelper} from "@tanstack/react-table";
import Button from "../../components/UI/Button";
import StaticMenuVideoTableActions from "../../views/videos/StaticMenuVideoTableActions";
import Card from "../../components/UI/Card";
import {useEffect, useState} from "react";
import {getHankookTvVideos, getStaticMenusVideo} from "../../api/staticMenuVideo";
import Table from "../../components/UI/Table";
import VideoPlayerComponent from "../../views/videos/VideoPlayerComponent";
import VideoUploadFormComponent from "../../views/videos/VideoUploadFormComponent";
import VideoLinksActions from "../../views/videos/VideoLinksActions";
import SyncHankookTvVideos from "../../views/videos/SyncHankookTvVideos";
import VideoStatusComponent from "../../views/videos/VideoStatusComponent";


const HomeShoppingVideoUpload = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//vjs.zencdn.net/8.17.3/video.min.js';
        script.async = true;

        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '//vjs.zencdn.net/8.17.3/video-js.min.css';

        document.head.appendChild(script);
        document.head.appendChild(link);

        return () => {
            document.head.removeChild(script);
            document.head.removeChild(link);
        };
    }, []);

    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor('title', {
            id: 'title',
            header: 'Title',
        }),
        columnHelper.accessor('url', {
            id: 'url',
            header: 'Video Type',
            cell: props => <span >{props.row.original.type}</span>
        }),
        columnHelper.accessor('status', {
            id: 'status',
            header: 'Status',
            cell: props =>  <VideoStatusComponent row={props.row.original} />
        }),
        columnHelper.accessor('sort', {
            id: 'sort',
            header: 'Sort',
        }),
        columnHelper.accessor('id', {
            id: 'id',
            header: 'Play',
            cell: props => <span className={'cursor_pointer font_18p'} onClick={()=>handlePlayer(props.row.original)}>{props.row.original.type === 'youtube' ? <i className="fab fa-youtube"></i> :
                <i className="fas fa-play-circle"></i>}</span>
        }),

        columnHelper.display( {
            id: 'actions',
            header: "Actions",
            classes:'text_right',
            cell: props => <StaticMenuVideoTableActions   editRecord={handleEditVideo} row={props.row.original} />
        }),
    ];
    const [video, setVideo] = useState(null);
    const [videos, setVideos] = useState([]);
    const [videoPlayModal, setVideoPlayModal] = useState(false);
    const [formModal, setFormModal] = useState(false);
    const [syncModal, setSyncModal] = useState(false);

    const handlePlayer =(row) =>{
        setVideo(row)
        setVideoPlayModal(true)
    }
    const closeModal =() =>{
        setVideo(null)
        setVideoPlayModal(false)
        setFormModal(false)
        setSyncModal(false)
    }

    const handleEditVideo = (video)=>{
        setVideo(video)
        setFormModal(true)
    }

    useEffect(() => {
        if(!videos.length){
            fetchHankookTvVideos().then(r => {})
        }
    }, []);

    const fetchHankookTvVideos = async () => {
        const videos = await getHankookTvVideos();
        setVideos(videos)
    }

    return (
        <>

            <Card title={"Videos"} CardActions={<VideoLinksActions openFormModal={setFormModal} openSyncModal={setSyncModal} />}>
                <Table
                    queryKey={"static-menu-videos"}
                    columns={columns}
                    queryParams={{per_page: 10}}
                    queryFn={getStaticMenusVideo}
                />
            </Card>


            <VideoPlayerComponent video={video} modal={videoPlayModal} closeModal={closeModal} />

            <VideoUploadFormComponent  setModalStatus={closeModal} editVideo={video} modalStatus={formModal} />

            <SyncHankookTvVideos modalStatus={syncModal} closeModal={closeModal} videos={videos} />

        </>
    );
}

export default HomeShoppingVideoUpload