import Modal from "../../components/UI/Modal";
import HankookTVVideoItem from "./HankookTVVideoItem";

const SyncHankookTvVideos = ({modalStatus, closeModal, videos}) => {
    return (
        <>
            <Modal open={modalStatus} setOpen={closeModal} size={"modal_1200p"}>
                <div className="row">
                    {videos && videos.map((video, index) => (
                        <HankookTVVideoItem video={video} key={index} closeModal={closeModal}/>
                    ))}
                </div>
            </Modal>
        </>
    );
}
export default SyncHankookTvVideos;