import Modal from "../../components/UI/Modal";

const VideoPlayerComponent = ({modal, video, closeModal}) => {
    return (
        <Modal open={modal} setOpen={closeModal} size={"modal_850p"}>
            {video &&
                <>
                    {video.type === 'video' || video.type === 'HankookVideo'?
                        <video
                            id="my_video"
                            className="video-js vjs-default-skin video_player "
                            controls
                            preload="auto"
                            width="600"
                            height="300"
                            data-setup="{}"
                        >
                            <source src={video.url} type="video/mp4"/>

                        </video>
                        :
                        <>
                            <div className="youtube-embed" dangerouslySetInnerHTML={{ __html: video.url }}>

                            </div>
                        </>
                    }
                </>
            }
        </Modal>
    );
}

export default VideoPlayerComponent