import Button from "../../components/UI/Button";

const VideoLinksActions = ({openSyncModal, openFormModal})=> {
    return (
        <>
        <Button
            text="Sync Video"
            onClickHandler={()=> openSyncModal(true)}
            variant={"btn_black"}
            classes={'mr_10'}
        />
        <Button
            text="+ Add a New Video"
            onClickHandler={()=> openFormModal(true)}
            variant={"btn_black"}
        />
        </>
    );
}

export default VideoLinksActions