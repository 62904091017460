import {confirmAlert,} from "react-confirm-alert";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {useState} from "react";
import Button from "../../components/UI/Button";
import {deleteStaticMenuVideo} from "../../api/staticMenuVideo";

const StaticMenuVideoTableActions = ({row, editRecord}) =>{
    const [toastId, setToastId] = useState(null);

    const queryClient = useQueryClient()

    const {mutate, isPending} = useMutation({
        mutationFn: ()=>deleteStaticMenuVideo({id:row.id}),
        onMutate: () => {
            setToastId(toast.loading("Please wait..."))
        },
        onSuccess: (response, payload) => {
            toast.update(toastId, { render: "Video Deleted!", type: "success", isLoading: false, autoClose: true  });
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey[0] === 'static-menu-videos',
            });
        },
        onError: () => {
            toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
        }
    })

    const editHandler = ()=>{
        editRecord(row)
    }

    const deleteHandler = () => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        mutate({id: row.id})
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    },
                }
            ]
        });
    }
    return (
        <>

            <Button
                text="Edit"
                classes={"mr_5"}
                variant={"has_border_radius btn_transparent"}
                size={'btn_sm'}
                onClickHandler={editHandler}
            />
            <Button
                text="Delete"
                variant={"btn_danger"}
                isPending={isPending}
                classes={'btn_black has_border_radius'}
                size={'btn_sm'}
                onClickHandler={deleteHandler}
            />
        </>
    );
}

export default StaticMenuVideoTableActions;