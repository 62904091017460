import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Button from "../../../../components/UI/Button";
import Card from "../../../../components/UI/Card";
import {setOrderForm} from "../../../../store/slices/orderSlice";
import {useParams} from "react-router-dom";

const CustomerInfoComponent = ({setValue, register, errors, order}) =>{
  const {orderForm} = useSelector(state => state.orders)
  const [customer, setCustomer] = useState(null)
  const dispatch = useDispatch()
  const params = useParams()
  useEffect(()=>{
    if(customer === null) {
      setCustomer(orderForm.customer)
    }
  }, [orderForm])

  useEffect(()=>{
      if(customer) {
        setValue('customer_id', customer.id)
      }
  }, [customer])


  const searchAgain = () => {
    dispatch(setOrderForm({customer: null, products: []}))
  }

  return(
    <Card>
      <h1 className="common_sub_title d_flex_btwn mt_0 mb_5">
        <span> Customer Information</span>
      </h1>

      {customer ?
      <div className="checkout_summery ">
        <table className="table border_none">
          <tbody>
          <tr>
            <td className="text_left width_100p fw_600">Name</td>
            <td>{customer.name}</td>
          </tr>
          <tr>
            <td className="text_left fw_600">Email</td>
            <td>{customer.email}</td>
          </tr>
          <tr>
            <td className="text_left fw_600">Phone</td>
            <td>{customer.phone ? customer.phone : order?.shipping_mobile}</td>
          </tr>

          </tbody>
        </table>
      </div>
        : <div className="checkout_summery ">
          <p>Name: {order.shipping_name}</p>
          {order.shipping_mobile && <p>Phone: {order.shipping_mobile}</p>}
          {order.shipping_email && <p>Email: {order.shipping_email}</p>}

          <span className={'color_warning'}>Guest Checkout</span>

        </div>
      }
    </Card>
  );
}

export default CustomerInfoComponent