import Card from "../../../../components/UI/Card";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import Button from "../../../../components/UI/Button";

const OrderSummaryComponent = ({setValue, control, errors, getValues, register, watch}) => {

  const {products} = useSelector(state => state.orders.orderForm);
  const {orderForm} = useSelector(state => state.orders);
  const [orderSummary, setOrderSummary] = useState({total:0, subTotal: 0, discount: 0, shippingCost: 0, free_shipping_by: ''})

  const params = useParams()

  useEffect(()=>{
    if(params && params.id && orderForm){
      setOrderSummary({
        total:orderForm.total,
        subTotal: orderForm.sub_total,
        discount: orderForm.discount,
        shippingCost: orderForm.free_shipping === 1? 0 : orderForm.shipping_cost,
        free_shipping_by: orderForm.free_shipping ? orderForm.free_shipping_by : '',
      })
    }
  }, [params, orderForm])


  useEffect(()=>{
    if(!orderForm.free_shipping ) {
      setOrderSummary(rows => ({...rows, shippingCost: +getValues('shipping_cost')}))
    }
  }, [watch('shipping_method_id')])

  useEffect(()=>{
    calculator()
  }, [products, orderSummary.discount, orderSummary.shippingCost])

  const calculator = () => {
    let subTotal = 0
    products.map(item => {
      subTotal += (item.quantity * item.price)
    })
    let total = subTotal
    total = total + orderSummary.shippingCost
    if(orderSummary.discount <= subTotal){
      total = total - orderSummary.discount
    }
    setValue('discount', orderSummary.discount)
    setOrderSummary(rows => ({...rows, subTotal: subTotal, total: total}))

  }

  const discountChange = (e) => {
    setOrderSummary(rows => ({...rows, discount: +e.target.value}))
  }

  return(
    <Card>
      <h1 className="common_sub_title d_flex_btwn mt_0 mb_5">
        <span>Order Summary</span>
      </h1>
      <div className="checkout_summery">
      <table className="table border_none">
        <tbody>
        <tr>
          <td className={"text_left"}>Sub Total</td>
          <td className={"text_right color_grey"}>${orderSummary.subTotal ? orderSummary.subTotal.toFixed(2) : '00.00'}</td>
        </tr>
        <tr>
          <td className={"text_left"}>Discount: {orderForm.coupon_code && <Button text={`Code: ${orderForm.coupon_code}`} classes={'has_border_radius'} size={'btn_sm'} /> } </td>
          <td className={"text_right color_grey"}>
            ${orderSummary.discount?.toFixed(2)}
          </td>
        </tr>
        <tr>
          <td className={"text_left"}>Shipping Cost:</td>
          <td className={"text_right color_grey"}>
            {orderForm.free_shipping ? <span className={'text_red mr_5'}>Free </span> : ''}
            ${orderSummary.shippingCost ? orderSummary.shippingCost.toFixed(2) : '00.00'}
          </td>
        </tr>
        {orderForm.group?.refund_amount  && 
          <tr>
            <td className={"text_left"}>Refunded:</td>
            <td className={"text_right color_grey"}> 
              ${orderForm.group?.refund_amount ? orderForm.group?.refund_amount.toFixed(2) : '00.00'}
            </td>
          </tr>
        }
        <tr>
          <td className={"text_left"}>Total:</td>
          <td className={"text_right font_16p"}>${orderSummary.total ? orderSummary.total.toFixed(2) : '00.00'}</td>
        </tr>
        </tbody>
      </table>
      </div>
    </Card>
  );
}

export default OrderSummaryComponent