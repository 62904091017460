import Button from "../../components/UI/Button";
import {addHankookTV} from "../../api/staticMenuVideo";
import {toast} from "react-toastify";
import {useQueryClient} from "@tanstack/react-query";

const HankookTVVideoItemActions = ({video, closeModal}) => {
    const queryClient = useQueryClient();

    const handleSelectClick = async() =>{
        await addHankookTV(video).then(()=>{
            toast.success('Video has been selected!' );
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey[0] === 'static-menu-videos',
            });
            closeModal(false)
        })
            .catch((err)=>{
                let error = JSON.parse(err.message)
                toast.error(error.message)
            })
        ;
    }

    return (
        <>
            <div className="hankook_tv_video_actions d_flex_btwn">
                <Button
                    text={'Select'}
                    onClickHandler={handleSelectClick}
                    classes={'width_full'}
                />
            </div>
        </>
    );
}
export default HankookTVVideoItemActions;