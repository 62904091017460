import {useState} from "react";
import Button from "../../../components/UI/Button";
import {updateTracingNumber} from "../../../api/orders";
import {toast} from "react-toastify";
const TrackingNumber = ({order}) => {
  const [button, setButton] = useState(false);
  const [form, setForm] = useState({tracingNumber: order ? order.tracking_number : ''});
  const handleChange = (value) => {
    setForm({tracingNumber: value});
  }

  const updateTrackingNumber = async () => {
    await updateTracingNumber(order.id, form).then(()=>{
      toast.success("Tracking number updated successfully.");
      setButton(false);
    })
  }

  return (
    <tr>
      <td className="text_left fw_600">Tracking Number</td>
      <td>
        <div className={'p_relative'}>
          <input
            className={'form_global'}
            type="text"
            disabled={!button}
            value={form.tracingNumber}
            onChange={(e) => {
              handleChange(e.target.value)
            }}
            placeholder="Tracking Number"/>
          <div className="tracing_div p_absolute">
            {!button ?
              <Button
                onClickHandler={() => setButton(!button)}
                text={'update'}
                classes={"height_40p"}
              />
              :
              <>
                <Button
                  text={'save'}
                  classes={"height_40p"}
                  onClickHandler={updateTrackingNumber}
                  variant={"btn_info mr_5"}
                />

                <Button
                  text={'cancel'}
                  onClickHandler={() => setButton(!button)}
                  classes={"height_40p"}
                />
              </>
            }

          </div>
        </div>

      </td>
    </tr>
  )
}
export default TrackingNumber