import API from "../util/api";

export const getStaticMenusVideo = async (params) => {
    let data = null;

    await API.get('/static-menu-videos', {params})
        .then(res => {
            data = res.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });
    return data;
}
export const getStaticMenuVideoDetails = async (payload) => {
    let data = null;
    await API.get('/static-menu-videos/' + payload)
        .then(res => {

            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}
export const deleteStaticMenuVideo = async (params) => {
    let data = null;

    await API.delete('/static-menu-videos/'+params.id)
        .then(res => {
            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}

export const storeStaticMenuVideo = async (payload) => {
    let data = null;

    await API.post('/static-menu-videos', payload)
        .then(res => {
            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}

export const sortStaticMenuVideo = async (payload) => {
    let data = null;
    await API.post('/static-menu-videos/sort', payload)
        .then(res => {
            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}
export const addHankookTV = async (payload) => {
    let data = null;
    await API.post(`/hankook-tv-video`, payload)
        .then(res => {
            data = res.data
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}

export const updateStaticMenuVideo = async (id, payload) => {
    let data = null;
    payload.append('_method','PATCH')
    await API.post(`/static-menu-videos/${id}`, payload)
        .then(res => {
            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}


export const getHankookTvVideos = async (payload) => {
    let data = null
    try {
        const response = await fetch('https://tvhankook.com/home-shopping-videos');

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const json = await response.json();

        data = json.data
    } catch (error) {
       console.log(error.message);
    }

    return data;
}


export const updateStaticMenuVideoStatus = async (payload, id) => {
    let data = null;
    await API.post(`/static-menu-videos-status/${id}`, payload)
        .then(res => {
            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}


