import Checkbox from "../../components/UI/form/Checkbox";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {updateStaticMenuVideoStatus} from "../../api/staticMenuVideo";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../util/validationMessage";

const VideoStatusComponent = ({row}) => {
    const {getValues, setValue, watch, reset, control, register, setError, clearErrors, handleSubmit, formState: { errors }} = useForm({
        defaultValues:{
            [`status_${row.id}`]: row.status == 1 ? true : false,
        }
    });
    const queryClient = useQueryClient();
    const onChangeHandler = (e) => {
        mutate({status: e.target.checked})
    }
    const [toastId, setToastId] = useState(null);
    const {isPending, mutate} = useMutation({
        mutationFn: (data) => updateStaticMenuVideoStatus(data, row.id),
        onMutate: () => {
            setToastId(toast.loading("Please wait..."))
        },
        onSuccess: (res) => {
            toast.update(toastId, {
                render: `Video status has been updated!.`,
                type: "success",
                isLoading: false,
                autoClose: true
            });
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey[0] === 'static-menu-videos',
            });
        },
        onError: (error) => {
            let errorMsg = "Something happened!";
            if (error && error.message && error.message.errors) {
                setValidationMessage(error.message.errors, setError);
                errorMsg = error.message.message;
            }
        }
    });

    return (
        <div className="video-status">
            <Checkbox name={`status_${row.id}`}
                      classes="mb_0 pt_10"
                      register={register}
                      onChangeHandler={onChangeHandler}
                      checked={getValues(`status`) ? 1 : 0} />
        </div>
    );
}
export default VideoStatusComponent