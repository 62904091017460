import RadioBox from "../../components/UI/form/RadioBox";
import Input from "../../components/UI/form/Input";
import Button from "../../components/UI/Button";
import Modal from "../../components/UI/Modal";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {storeStaticMenuVideo, updateStaticMenuVideo} from "../../api/staticMenuVideo";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../util/validationMessage";

const VideoUploadFormComponent = ({modalStatus, setModalStatus, editVideo}) => {
    const queryClient = useQueryClient();
    const [toastId, setToastId] = useState(null);


    const {getValues, setValue, watch, reset, control, register, setError, clearErrors, handleSubmit, formState: { errors }} = useForm({
        defaultValues:{
            status: '1',
            sort: 1,
            type: 'video',
        }
    });

    useEffect(() => {
        reset()
    }, [modalStatus])

    useEffect(() => {
        if(editVideo){
            setValue('type', editVideo.type);
            setValue('status', editVideo.status+'');
            setValue('title', editVideo.title);
            setValue('sort', editVideo.sort);
            setTimeout(()=>{
                setValue('url', editVideo.type === 'video' ? null : editVideo.url);
            },  250)
        }
    }, [editVideo])

    const {isPending, mutate} = useMutation({
        mutationFn: editVideo ? (data) => updateStaticMenuVideo(editVideo.id, data) : (data)=> storeStaticMenuVideo(data),
        onMutate: () => {
            setToastId(toast.loading("Please wait..."))
        },
        onSuccess: (res) => {
            toast.update(toastId, {
                render: `Video Link ${editVideo ? 'Updated' : 'Added'}`,
                type: "success",
                isLoading: false,
                autoClose: true
            });
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey[0] === 'static-menu-videos',
            });
            reset();
            setModalStatus(false)
        },
        onError: (error) => {
            let errorMsg = "Something happened!";
            if (error && error.message && error.message.errors) {
                setValidationMessage(error.message.errors, setError);
                errorMsg = error.message.message;
            }
        }
    });

    const onSubmit = (data) => {
        clearErrors();
        let file = null;

        if(data.type === 'video'){
            file = data.url ? data.url[0] : null;
        }

        let formData = new FormData()
        Object.keys(data).forEach(key => {
            formData.append(key, data[key] ? data[key] : '');
        })

        if(file)
            formData.append('url', file)

        mutate(formData)
    }

    return (
        <Modal title={`${editVideo ? 'Edit' : 'Add'} Video`} open={modalStatus} setOpen={setModalStatus} size={"modal_850p"}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="col_12">
                    <RadioBox label="Type"
                              name="type"
                              errors={errors}
                              inline={true}
                              options={[{id: "video", name: 'Video'}, {id: 'youtube', name: "Youtube"}, {id: 'HankookVideo', name: "HankookVideo"}]}
                              validationRules={{required: true, type: 'string'}}
                              register={register}/>
                </div>
                <div className="col_12">
                    <Input label={'Title'}
                           name="title"
                           errors={errors}
                           inline={true}
                           validationRules={{required: true, type: ''}}
                           register={register}/>
                </div>
                <div className="col_12">
                    <Input label={'Sort'}
                           name="sort"
                           errors={errors}
                           type={'number'}
                           inline={true}
                           validationRules={{required: true, type: ''}}
                           register={register}/>
                </div>
                <div className="col_12">
                    <Input label={watch('type') === 'video' ? 'Video' : 'Link'}
                           type={watch('type') === 'video' ? 'file' : 'text'}
                           name="url"
                           errors={errors}
                           inline={true}
                           validationRules={{required: editVideo ? false : true, type: ''}}
                           register={register}/>
                </div>
                <div className="col_12">
                    <RadioBox label="Status"
                              name="status"
                              errors={errors}
                              inline={true}
                              options={[{id: "1", name: 'Active'}, {id: '0', name: "Inactive"}]}
                              validationRules={{required: true, type: 'string'}}
                              register={register}/>
                </div>

                <div className="col_12">
                    <div className="d_flex_end">
                        <Button
                            text="save"
                            isPending={isPending}
                            type={"submit"}
                        />
                    </div>
                </div>
            </form>
        </Modal>
    );
}

export default VideoUploadFormComponent