import Card from "../../components/UI/Card";
import HankookTVVideoItemActions from "./HankookTVVideoItemActions";

const HankookTVVideoItem = ({video, closeModal}) => {
    return (
        <div className="col_3">
            <Card cardBodyCls={'p_5'}>
                <div className="hankook_tv_item">
                    <img
                        src={video.preview_image_thumbs}
                        className={'width_full'}
                        alt={video.id}
                    />
                    <HankookTVVideoItemActions video={video} closeModal={closeModal} />
                </div>


            </Card>
        </div>
    );
}

export default HankookTVVideoItem