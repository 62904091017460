import Card from "../../../../components/UI/Card";
import ShippingMethodsList from "./ShippingMethodsList";
import {useState} from "react";
import Button from "../../../../components/UI/Button";
import {useSelector} from "react-redux";
import TrackingNumber from "../TrackingNumber";

const SelectShippingMethods = ({setValue}) => {
  const [shippingMethod, setShippingMethod] = useState(null)
  const [openModal, setModal] = useState(false)
  const order = useSelector(state => state.orders.orderForm);
  const selectNewShipping = (row) => {
    setValue('shipping_cost', row.fee)
    setValue('shipping_method_id', row.id)
    setShippingMethod(row)
  }

  return (
    <>
      <Card>
        <h1 className="common_sub_title d_flex_btwn mt_0 mb_5">
          Shipping Methods
          {/*<Button text={"Change Shipping Method"} onClickHandler={()=>setModal(!openModal)} />*/}
        </h1>
        {shippingMethod &&
        <div className="order_summery_table">
          <table className="table border_none">
            <tbody>
            <tr>
              <td className="text_left width_100p fw_600">Name</td>
              <td>{shippingMethod.name} </td>
            </tr>
            <tr>
              <td className="text_left fw_600">Courier</td>
              <td>{shippingMethod.courier ? shippingMethod.courier.name : ''}</td>
            </tr>
            <tr>
              <td className="text_left fw_600">Cost</td>
              <td>${shippingMethod.fee ? shippingMethod.fee.toFixed(2) : 'Actual Rate'}</td>
            </tr>
            <TrackingNumber order={order} />
            </tbody>
          </table>
        </div>
        }
      </Card>
      <ShippingMethodsList openModal={openModal} setModal={setModal}  setShippingMethod={selectNewShipping} />
    </>
  );
}

export default SelectShippingMethods